<template>
  <div>
    <Card class="mb-5">
      <template v-slot:header> Редактировать курс </template>
      <div class="row">

        <div class="col-sm-12 mb-3">
          <div class="input-label">
            <span>Статус</span>
          </div>
          <Select
            :data="statuses"
            :selected="selectedStatus"
            @change="selectedStatus = $event"
          >
            <template v-slot:placeholder="{ selectedItem }">
              <div
                v-if="selectedItem !== null && selectedItem !== undefined"
                class="d-flex flex-row align-items-center"
              >
                <div>{{ selectedItem.name }}</div>
              </div>
            </template>
            <template v-slot:default="{ item }">
              <div class="d-flex flex-row align-items-center text-">
                <div class="w-75">{{ item.name }}</div>
              </div>
            </template>
          </Select>
        </div>

        <div class="col-12">
          <Input
            v-model="course.name"
            placeholder="Курс #1"
            label="Название курса"
            required
          ></Input>
        </div>
        <div class="col-sm-12">
          <Input
            v-model="course.init_message"
            tag="textarea"
            rows="6"
            placeholder="О курсе"
          >
            <template v-slot:label>
              <label>Первое сообщение</label>
            </template>
          </Input>
        </div>

        <div class="col-sm-12">
          <Input
            v-model="course.url_trailer"
            placeholder="ссылка на трейлер курса"
          >
            <template v-slot:label>
              <label>Трейлер курса</label>
            </template>
          </Input>
        </div>

        <div class="col-sm-12">
          <Input
            v-model="course.url_coursera"
            placeholder="Cсылка на Coursera"
          >
            <template v-slot:label>
              <label>Ссылка курса на Coursera</label>
            </template>
          </Input>
        </div>

        <div class="col-sm-12">
          <Input
            v-model="course.num_days"
            placeholder=""
            :disabled="true"
          >
            <template v-slot:label>
              <label>Сколько обр. дней</label>
            </template>
          </Input>
        </div>

        <div class="col-sm-12">
          <Input
            v-model="course.points_for_cert"
            placeholder=""
            :disabled="true"
          >
            <template v-slot:label>
              <label>Сколько очков для сертификата</label>
            </template>
          </Input>
        </div>
      </div>

      <div>
        <div class="input-label">
          <label>Приветственное изображение курса</label>
        </div>
        <Dropzone
          v-slot:default="{ files, removeFileByIndex, active, browse }"
          ref="dropzone"
          @change="course_images = $event"
        ></Dropzone>
      </div>

      <div v-if="course_images && course_images.length > 0">
        <div class="mt-4">Добавленные файлы:</div>
        <div v-for="file in course_images">
          <div class="input-label">
            <label>{{ file.name }}</label>
          </div>
          <img style="max-width: 100px;" :src="file.src"/>
        </div>
      </div>

      <div class="input-group pt-5">
        <Button @click="updateCourse" type="outline-light-bg-success"
          >Обновить</Button
        >
      </div>
    </Card>

    <Card v-if="days && days.length > 0" class="mb-5">
      <template class="mb-3" v-slot:header>Образовательные дни</template>
      <Accordion>
        <AccordionItem :disabled=true v-for="(day, tindex) in days" :key="tindex">
          <b slot="default">{{tindex + 1}}) {{ day.name }}</b>
          <Badge class="mx-1" pill :type="getPillByStatus(day.status)">
            {{getStatusName(day.status)}}
          </Badge>
          <Button @click="dayUp(day)" type="outline-light-bg-default" class="mx-1 px-1" style="float:right;">
            <i class="bi bi-arrow-up"></i>
          </Button>
          <Button @click="dayDown(day)" type="outline-light-bg-default" class="mx-1 px-1" style="float:right;">
            <i class="bi bi-arrow-down" style="float:right;"></i>
          </Button>
        </AccordionItem>
      </Accordion>
    </Card>
  </div>
</template>

<script>
import CourseService from "../../services/CourseService";
import swal from "sweetalert";
import FormData from 'form-data';
import DayService from '../../services/DayService';

var defaultData = {
  id: "",
  name: "",
  url_trailer: "",
  url_coursera: "",
  init_message: "О курсе:\n\nЦель курса:\n\nЦелевая аудитория:",
  images: [],
  points_for_cert: 0,
  num_days: 0,
  status: CourseService.STATUS_ACTIVE
};

export default {
  name: "EditCourseForm",

  data() {
    return {
      course: {
        id: defaultData.id,
        name: defaultData.name,
        init_message: defaultData.init_message,
        url_trailer: defaultData.url_trailer,
        url_coursera: defaultData.url_coursera,
        points_for_cert: defaultData.points_for_cert,
        num_days: defaultData.num_days,
        status: defaultData.status
      },
      course_images: defaultData.images,
      statuses: CourseService.getStatuses(),
      selectedStatus: CourseService.STATUS_ACTIVE,
      days: []
    };
  },

  methods: {
    async updateCourse() {
      if (!this.course.name) {
        swal("Ошибка!", "Поле «Название курса» не заполнено.", "warning");
        return;
      }
      if (!this.course.init_message) {
        swal("Ошибка!", "Поле «Первое сообщение» не заполнено.", "warning");
        return;
      }
      if (!this.course_images || this.course_images.length === 0) {
        swal("Ошибка!", "Поле «Приветственное изображение курса» не заполнено.", "warning");
        return;
      }

      var form = new FormData()
      form.append('status', this.selectedStatus.id);
      form.append('name', this.course.name);
      form.append('init_message', this.course.init_message);
      form.append('url_trailer', this.course.url_trailer);
      form.append('url_coursera', this.course.url_coursera);
      // console.log(this.course_images);

      if (this.course_images[0] instanceof File) {
        form.append('image', this.course_images[0]);
      } else {
        const course_image = await this.urltoFile(this.course_images[0].src, this.course_images[0].name, this.course_images[0].contentType);
        form.append('image', course_image);
      }

      CourseService.update(this.course.id, form)
        .then(response => {
          swal("Успех!", "Курс обновлен", "success");
        })
        .catch(error => {
          let err_message = "Что то пошло не так";
          if (error.response && error.response.data.message) {
              err_message = error.response.data.message;
          }
          swal("Ошибка!", err_message, "error");
        });
    },

    async updateDay(day) {
      DayService.update(day._id, day).then(response => {
      }).catch(error => {
        console.log(error);
        let err_message = "Что то пошло не так";
        if (error.response && error.response.data.message) {
            err_message = error.response.data.message;
        }
        swal("Ошибка!", err_message, "error");
      });
    },

    async updateDays()
    {
      const course = await CourseService.get(this.$route.params.id);
      this.days = [];
      await this.$nextTick();

      for (const key in course.days) {
        await this.$set(this.days, key, course.days[key]);
      }

      await this.$forceUpdate();
    },

    async dayUp(currentDay) {
      const currDayIndex = this.days.findIndex((day) => day._id == currentDay._id);
      const prevDayIndex = currDayIndex - 1;
      const prevDay = this.days[prevDayIndex];
      const vm = this;

      if (prevDay) {
        const currNumQueue = currentDay.num_in_queue;
        const prevNumQueue = prevDay.num_in_queue;

        if (currNumQueue == prevNumQueue) {
          if (currNumQueue == 0) {
            prevDay.num_in_queue += 1;
          } else {
            currentDay.num_in_queue -= 1;
          }
        } else {
          currentDay.num_in_queue = prevNumQueue;
          prevDay.num_in_queue = currNumQueue;
        }

        await this.updateDay(currentDay);
        await this.updateDay(prevDay);
        setTimeout(async function() {
          await vm.updateDays();
        }, 1000);
      }
    },

    async dayDown(currentDay) {
      const currDayIndex = this.days.findIndex((day) => day._id == currentDay._id);
      const nextDayIndex = currDayIndex + 1;
      const nextDay = this.days[nextDayIndex];
      const vm = this;

      if (nextDay) {
        const currNumQueue = currentDay.num_in_queue;
        const nextNumQueue = nextDay.num_in_queue;

        if (currNumQueue == null) {
          if (nextNumQueue == null) {
            currentDay.num_in_queue = 0
          } else {
            currentDay.num_in_queue = nextNumQueue + 1;
          }
        } else if (currNumQueue == nextNumQueue) {
          currentDay.num_in_queue += 1;
        } else {
          currentDay.num_in_queue = nextNumQueue;
          nextDay.num_in_queue = currNumQueue;
        }

        await this.updateDay(currentDay);
        await this.updateDay(nextDay);
        setTimeout(async function() {
          await vm.updateDays();
        }, 1000);
      }
    },

    async urltoFile(url, filename, mimeType){
        return await (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    },

    getBase64 (file, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(file);
    },

    getStatusName(status) {
      return DayService.getStatusName(status);
    },

    getPillByStatus(status) {
      switch (status) {
        case DayService.STATUS_ACTIVE:
            return 'success';
        case DayService.STATUS_ARCHIVE:
            return 'warning';
      }
    },

    clean() {
      this.days = [];
    },

    async init() {
      let vm = this;
      let course = await CourseService.get(this.$route.params.id);
      this.course.id = this.$route.params.id;
      this.course.name = course.name;
      this.course.init_message = course.init_message;
      this.course.url_trailer = course.url_trailer;
      this.course.url_coursera = course.url_coursera;
      this.course.points_for_cert = course.points_for_cert;
      this.course.num_days = course.num_days;
      this.course.status = course.status;
      let course_image = await Object.assign({}, course.init_image, {'src': 'data:' + course.init_image.contentType + ';base64,' + course.init_image.data});
      this.selectedStatus = this.statuses.find((status) => status.id == course.status);

      await vm.$nextTick();
      vm.course_images = [];
      vm.course_images.push(course_image);

      course.days.forEach((day, index) => {
        this.$set(this.days, index, day);
      })
    }
  },

  async mounted() {
    await this.init();
  },

  watch: {
    course_images: function(images){
      let vm = this;
      images.forEach(image => {
        if (image.src) {
          return;
        }

        this.getBase64(image, function(base64Data){
            image.src = base64Data;
            vm.$forceUpdate();
        });
      });
    },
    $route (to, from){
      this.clean();
      this.init();
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped>
</style>